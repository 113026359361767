import {Competition} from "../../src/interfaces/interfaces";
import {CompetitionType} from "../../src/interfaces/enums";

const zavody: Competition[] = [
    {
        name: 'Veselský pohár',
        date: '14.10.2023',
        type: CompetitionType.spolecky,
        club: 'SK MG Veselí nad Moravou',
    },
    {
        name: 'O Hradeckou stuhu',
        date: '15.10.2023',
        type: CompetitionType.spolecky,
        club: 'SK MG Ajur Hrades Králové',
        results: 'O Hradeckou stuhu 2023 - výsledky.pdf',
    },
    {
        name: 'TRASKO CUP',
        date: '21.10.2023',
        type: CompetitionType.spolecky,
        club: 'SK TRASKO Vyškov',
        results: 'TRASKO Cup 2023 - výsledky.pdf',
    },
    {
        name: 'Sametová stuha - Měnín',
        date: '22.10.2023',
        type: CompetitionType.spolecky,
        club: 'SKP MG Brno',
    },
    {
        name: 'South Moravia CUP - Měnín',
        date: '28.10.2023',
        type: CompetitionType.spolecky,
        club: 'SKP MG Brno',
    },
    {
        name: 'Oblastní přebor ve společných skladbách - Vyškov',
        date: '4.11.2023',
        type: CompetitionType.spolecky,
        club: 'SK TRASKO Vyškov',
        results: 'OP SS 2023 - výsledky.pdf',
    },
    {
        name: 'Creative Cups CUP - Přerov',
        date: '5.11.2023',
        type: CompetitionType.spolecky,
        club: 'TJ Spartak Přerov',
    },
    {
        name: 'RYCON CUP - České Budějovice',
        date: '11.11.2023',
        type: CompetitionType.spolecky,
        club: 'SK MG Máj České Budějovice',
    },
    {
        name: 'MČR společných skladeb linie A - Ostrava',
        date: '18.11.2023',
        type: CompetitionType.spolecky,
        club: 'SSK Vítkovice, SK MG Havířov',
        results: 'MČR SS 2023 - výsledky.pdf',
    },
    {
        name: 'MČR dvojic a trojic - Ostrava',
        date: '19.11.2023',
        type: CompetitionType.spolecky,
        club: 'SSK Vítkovice, SK MG Havířov', 
        results: 'MČR DT 2023 - výsledky.pdf',
    },
    {
        name: 'KZ I - Vyškov',
        date: '18.2.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'KZ I - vysledky.pdf',
    },
    {
        name: 'KZ II - Vyškov',
        date: '25.2.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'KZ II - vysledky.pdf',
    },
    {
        name: 'KZ III - Vyškov',
        date: '3.3.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'KZ III - vysledky.pdf',
    },
    {
        name: 'Mezioddílový závod Mantila Brno',
        date: '9.3.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Mantila Brno',
        results: 'MZ 9.3 - výsledky.pdf',
    },
    {
        name: 'Mezioddílový závod Vyškov',
        date: '17.3.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'MZ Vyškov 2024 - výsledky.pdf',
    },
    {
        name: 'ESG CUP I. Třebíč',
        date: '24.3.2024',
        type: CompetitionType.esg,
        club: 'SK MG Baver Třebíč',
        results: 'ESG Cup I - BAVER CUP - výsledky.pdf',
    },
    {
        name: 'Mantilácký tulipánek - Brno',
        date: '6.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Mantila Brno',
        results: 'Mantilácký tulipánek 2024 - výsledky.pdf',
    },
    {
        name: 'ESG CUP II. + Regionální přebor Morava - Vyškov',
        date: '7.4.2024',
        type: CompetitionType.esg,
        club: 'SK TRASKO Vyškov',
        results: 'ESG II - výsledky.pdf',
    },
    {
        name: 'Přerovská stuha',
        date: '13.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'TJ Spartak Přerov',
        results: 'Přerovská stuha 2024 - výsledky.pdf',
    },
    {
        name: '14.4.AJUR CUP - Hradec Králové',
        date: '14.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Ajur Hradec Králové',
        results: 'AJUR Cup 2024 - výsledky.pdf',
    },
    {
        name: 'UPREAL CUP - Tábor',
        date: '14.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'Akademie MG KP',
        results: 'UPREAL Cup 2024 - výsledky.pdf',
    },
    {
        name: 'O pohár města Vyškova',
        date: '20.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'O pohár města Vyškova 2024 - výsledky.pdf',
    },
    {
        name: 'OP SEN B, JUN B, NST B, NML B - Mantila Brno',
        date: '27.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Mantila Brno',
        results: 'OP B - výsledky.pdf',
    },
    {
        name: 'OP NML A, NST A, - Vyškov',
        date: '28.4.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'OP NML A a NST A 2024 - výsledky.pdf',
    },
    {
        name: 'ESG CUP IV. + MČR v krátkém programu - Klánovice',
        date: '1.5.2024',
        type: CompetitionType.esg,
        club: 'Astra Klánovice',
        results: 'ESG Cup IV a MČR KP - výsledky.pdf',
    },
    {
        name: 'OP JUN A + SEN A - Tart Brno',
        date: '1.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Moravská Slavia Brno',  
        results: 'OP JUN A a SEN A.pdf',
    },
    {
        name: 'Pohár města Ostravy',
        date: '4.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'SSK Vítkovice',
    },
    {
        name: 'Milevský pohárek',
        date: '4.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'Proactive Milevsko',
        results: 'Milevský pohárek - výsledky.pdf',
    },
    {
        name: 'Spring Cup',
        date: '5.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK MG Moravská Slavia Brno',
        results: 'Spring Cup - výsledky.pdf',
    },
    {
        name: 'ESG CUP V. + OP v ESG - Mantila Brno',
        date: '8.5.2024',
        type: CompetitionType.esg,
        club: 'SK MG Mantila Brno',
        results: 'ESG Cup V - výsledky.pdf',
    },
    {
        name: 'MČR JUN B',
        date: '11.5.2024',
        type: CompetitionType.jednotlivci,  
        club: 'SK Provo Brno',
        results: 'MČR JUN B - výsledky.pdf',
    },
    {
        name: 'MČR NST B',
        date: '12.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK Provo Brno',
        results: 'MČR NST B - výsledky.pdf',
    },
    {
        name: 'MČR SEN B - Milevsko',
        date: '18.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'Proactive Milevsko',
        results: 'MČR SEN B - výsledky.pdf', 
    },
    {
        name: 'Traskáček',
        date: '19.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK TRASKO Vyškov',
        results: 'Traskáček 2024 - výsledky.pdf',
    },
    {
        name: 'Budapešť Maďarsko',
        date: '23.-26.5.2024',
        type: CompetitionType.jednotlivci,
        club: '',
    },
    {
        name: 'MČR NML A + NST A - Praha',
        date: '25.5.2024',
        type: CompetitionType.jednotlivci,
        club: 'TJ SK Prague',
        results: 'MČR NML a NST A - výsledky.pdf',
    },
    {
        name: 'MČR JUN A + SEN A - Jihlava',
        date: '1.-2.6.2024',
        type: CompetitionType.jednotlivci,
        club: 'SK Jihlava, TJ Sokol Bedřichov',
    },
    {
        name: 'OP NNML - Blučina',
        date: '8.6.2024',
        type: CompetitionType.jednotlivci,
        club: 'SKP MG Brno',
    },
    {
        name: 'MČR ESG dlouhý program a ESG Cup VI - Olomouc',
        date: '9.6.2024',
        type: CompetitionType.esg,
        club: 'GK Velký Týnec',
        results: 'ESG Cup VI - výsledky.pdf',
    },
     {
        name: 'Veselský pohár',
        date: '12.10.2024',
        type: CompetitionType.spolecky,
        club: 'SK MG Veselí nad Moravou',
    },
    {
        name: 'O Hradeckou stuhu',
        date: '13.10.2024',
        type: CompetitionType.spolecky,
        club: 'SK MG Ajur Hrades Králové',
    },
    {
        name: 'SOUTH MORAVIA CUP - Měnín',
        date: '19.10.2024',
        type: CompetitionType.spolecky,
        club: 'SKP MG Brno',
    },
    {
        name: 'TRASKO CUP',
        date: '26.10.2024',
        type: CompetitionType.spolecky,
        club: 'SK TRASKO Vyškov',
    },
    {
        name: 'Oblastní přebor ve společných skladbách - Vyškov',
        date: '2.11.2024',
        type: CompetitionType.spolecky,
        club: 'SK TRASKO Vyškov',
    },
    {
        name: 'Creative Cups CUP - Přerov',
        date: '10.11.2024',
        type: CompetitionType.spolecky,
        club: 'TJ Spartak Přerov',
    },
    {
        name: 'Sametová stuha - Modřice',
        date: '16.11.2024',
        type: CompetitionType.spolecky,
        club: 'SKP MG Brno',
    },
]

export default zavody
