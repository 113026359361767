<template>
  <div class="flex flex-col mx-3 my-5 gap-3">
    <a href="https://www.facebook.com/sktrasko" target="_blank" class="h-[50px] w-[50px] rounded laptop:hover:scale-105 transition"><img src="/obrazky/Facebook.svg.png" alt="" class="object-cover rounded-full shadow-md"></a>
    <a href="https://www.instagram.com/sk_trasko_vyskov_/" target="_blank" class="h-[50px] w-[50px] rounded-2xl laptop:hover:scale-105 transition"><img src="/obrazky/Instagram.svg.png" alt="" class="object-cover rounded-2xl shadow-md"></a>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>