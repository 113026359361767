const carouselHeading = 'Naše děvčata na Mistrovství České republiky 2023 ve společných skladbách:'

const pictures = [
    {image: 'car1.jpg'},
    {image: 'car2.jpg'},
    {image: 'car3.jpg'},
    {image: 'car4.jpg'},
    {image: 'car5.jpg'},
    {image: 'car6.jpg'},
    {image: 'car7.jpg'},
    {image: 'car8.jpg'},
    {image: 'car9.jpg'}
]

export {pictures, carouselHeading}